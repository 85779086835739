<script>
export default {
  name: "reminderFrequency",
  mounted: function () {
    const userSettings = _.get(this, '$A.Auth.userSettings', {})
    this.setData(
        userSettings.reminderTimeOfDay,
        userSettings.reminderDays,
        ""
    );
  },
  data() {
    return {
      time_of_day_to_send: "",
      utcOffsetMin: 0,
      timeZone: "",
      selectedTimeZone: "",
      days: [false, false, false, false, false, false, false],
      isMobile: window.innerWidth <= 767,
    };
  },
  watch: {
    selectedTimeZone: function (after, before) {
      if (after !== undefined && after !== null) {
        let o = this.$A.TimeZoneService.GetTimeZoneByText(after);
        this.utcOffsetMin = o.offsets[0];
        this.timeZone = o.name;
      }
    },
  },
  computed: {
    frequency: function () {
      let d = this.days;
      let allFalse = true;
      let out = "";
      for (let day of d) {
        if (day) allFalse = false;
      }

      if (allFalse) {
        return "None";
      }

      out += d[0] ? "Sun, " : "";
      out += d[1] ? "Mon, " : "";
      out += d[2] ? "Tue, " : "";
      out += d[3] ? "Wed, " : "";
      out += d[4] ? "Tr, " : "";
      out += d[5] ? "Fri, " : "";
      out += d[6] ? "Sat, " : "";
      return out.replace(/,\s*$/, "");
    },
  },
  methods: {
    setData: function (timeOfDay, frequency = '', timeZone) {
      this.timeZone = timeZone;
      this.time_of_day_to_send = timeOfDay;

      if (this.timeZone === "") {
        this.selectedTimeZone = $A.TimeZoneService.GetTimeZoneFromCurrentBrowserZone().name;
      } else {
        this.timeZone = $A.TimeZoneService.GetTimeZoneByText(
            this.timeZone
        ).name;
        this.selectedTimeZone = this.timeZone;
      }
      this.days[0] = frequency.indexOf("Sun") !== -1 && frequency !== "None";
      this.days[1] = frequency.indexOf("Mon") !== -1 && frequency !== "None";
      this.days[2] = frequency.indexOf("Tue") !== -1 && frequency !== "None";
      this.days[3] = frequency.indexOf("Wed") !== -1 && frequency !== "None";
      this.days[4] = frequency.indexOf("Tr") !== -1 && frequency !== "None";
      this.days[5] = frequency.indexOf("Fri") !== -1 && frequency !== "None";
      this.days[6] = frequency.indexOf("Sat") !== -1 && frequency !== "None";
      this.$forceUpdate();
    },
  },
};
</script>

<template>
  <div class="col-md-12 d-flex justify-content-start flex-wrap">
    <div class="col-md-6">
      <div class="form-group">
        <label class="label-text ml-2">Default Reminder Notification Settings</label>
        <div class="weekDays-selector pl-2">
          <input
              type="checkbox"
              id="weekday-sun"
              class="weekday"
              v-model="days[0]"
          />
          <label for="weekday-sun">S</label>
          <input
              type="checkbox"
              id="weekday-mon"
              class="weekday"
              v-model="days[1]"
          />
          <label for="weekday-mon">M</label>
          <input
              type="checkbox"
              id="weekday-tue"
              class="weekday"
              v-model="days[2]"
          />
          <label for="weekday-tue">T</label>
          <input
              type="checkbox"
              id="weekday-wed"
              class="weekday"
              v-model="days[3]"
          />
          <label for="weekday-wed">W</label>
          <input
              type="checkbox"
              id="weekday-thu"
              class="weekday"
              v-model="days[4]"
          />
          <label for="weekday-thu">T</label>
          <input
              type="checkbox"
              id="weekday-fri"
              class="weekday"
              v-model="days[5]"
          />
          <label for="weekday-fri">F</label>
          <input
              type="checkbox"
              id="weekday-sat"
              class="weekday"
              v-model="days[6]"
          />
          <label for="weekday-sat">S</label>
        </div>
      </div>
    </div>
    <div class="col-md-6" style="margin-bottom: 14px; padding-right: 24px">
      <div class="form-group">
        <label class="label-text" style="margin-bottom: 10px">Default Notification Send Time</label>
        <input
            required="required"
            class="form-control contact-input"
            type="time"
            id="appt"
            name="appt"
            v-model="time_of_day_to_send"
            style="margin-bottom: 8px"
        />
        <select data-cy="timeZoneSelect" v-model="selectedTimeZone" class="form-control contact-input">
          <option v-for="tz in $A.TimeZoneService.GetAllTimeZoneNames()">
            {{ tz }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<style scoped>


.label-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #707073;
}

.contact-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}


</style>