<script>
import proofToggles from "../../components/global/proof-toggle";
import reminderFrequency from "../../components/form/reminderFrequency";
import { TYPES_ENUM } from "../../utils/constants";

const { FUNCTION } = TYPES_ENUM;

export default {
  name: "profilePreferences",
  components: {
    proofToggles,
    reminderFrequency,
  },
  mounted: function () {
    const setData = _.get(this, '$refs.reminderFreq.setData');
    if (!setData && typeof setData !== FUNCTION) return;

    setData(
        this.userSettings.reminderTimeOfDay,
        this.userSettings.reminderDays,
        this.userSettings.reminderTimeZone
    );
  },
  data: function () {
    return {
      user: this.$A.Auth,
    };
  },
  computed: {
    userSettings : function () {
      return _.get(this, 'user.userSettings', {});
    }
  },
  methods: {
    update: async function () {
      let reminderDays = this.$refs.reminderFreq.frequency;
      let reminderTimeOfDay = this.$refs.reminderFreq.time_of_day_to_send;
      let reminderTimeZone = this.$refs.reminderFreq.timeZone;

      let user = await this.$A.UserService.UpdateUserDefaultSettings(
          this.$A.Auth.id,
          this.userSettings.downloadFiles,
          this.userSettings.approveIndividualFiles,
          this.userSettings.viewAllComments,
          this.userSettings.approveWithChanges,
          this.userSettings.notifyFilesSent,
          this.userSettings.notifyFilesApproved,
          this.userSettings.notifyNewComments,
          this.userSettings.limitVersionVisibility,
          this.userSettings.blockCommentingAfterProofDecision,
          reminderDays,
          reminderTimeOfDay,
          reminderTimeZone
      );
      if (user.id !== undefined && user.id > 0) {
        this.user = user;
        this.$A.UpdateUserObjectAndBustCache(user);
      }
    },
  },
};
</script>

<template>
  <div class="on-mobile text-dark d-flex justify-content-center flex-md-wrap pb-5 mt-4 px-2">
    <div class="field-item border col-md-10">
      <div class="row">
        <div class="col-md-12">
          <div class="header-text px-4 pt-4 pb-3">Your Preferences</div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 d-flex justify-content-start flex-wrap">
          <!-- Default Proof Settings -->
          <div class="col-md-6">
            <div class="sub-header-text px-2" style="margin-bottom: 10px">Default Proof Settings</div>
            <div class="col-md-12 pl-4">
              <!--Toggle -->
              <div class="ashore-toggle" id="proof_toggle_download">
                <label class="switch">
                  <input
                      type="checkbox"
                      data-cy="downloadCheckbox"
                      v-model="userSettings.downloadFiles"
                  />
                  <div class="slider round"></div>
                </label>
                Download Files
                <label>
                  <img
                      id="proof_toggle_downloadfiles_icon"
                      src="/img/icon-info.svg"
                      class="ml-2"
                      style="height: 20px"
                  />
                  <b-tooltip
                      target="proof_toggle_downloadfiles_icon"
                      triggers="hover"
                  >
                    Turn this toggle on to allow approvers to download the files
                    in your proof.
                  </b-tooltip>
                </label>
              </div>
              <!--/Toggle -->
              <!--Toggle -->
              <div class="ashore-toggle" id="proof_toggle_approve">
                <label class="switch">
                  <input
                      type="checkbox"
                      v-model="userSettings.approveIndividualFiles"
                  />
                  <div class="slider round"></div>
                </label>
                Approve Individual Files
                <label>
                  <img
                      id="proof_toggle_approveindiviualfiles_icon"
                      src="/img/icon-info.svg"
                      class="ml-2"
                      style="height: 20px"
                  />
                  <b-tooltip
                      target="proof_toggle_approveindiviualfiles_icon"
                      triggers="hover"
                  >
                    Turn this toggle on to require approvers to make an approval
                    decision on each file in your proof. Otherwise, we'll ask for
                    one approval decision for all files in this proof.
                  </b-tooltip>
                </label>
              </div>
              <!--/Toggle -->
              <!--Toggle -->
              <div class="ashore-toggle" id="proof_toggle_comments">
                <label class="switch">
                  <input
                      type="checkbox"
                      v-model="userSettings.viewAllComments"
                  />
                  <div class="slider round"></div>
                </label>
                View All Comments
                <label>
                  <img
                      id="proof_toggle_viewallcomments_icon"
                      src="/img/icon-info.svg"
                      class="ml-2"
                      style="height: 20px"
                  />
                  <b-tooltip
                      target="proof_toggle_viewallcomments_icon"
                      triggers="hover"
                  >
                    Turn this toggle on to allow your approvers to see and
                    interact with other approver comments on a proof.
                  </b-tooltip>
                </label>
              </div>
              <!--/Toggle -->
              <!--Toggle -->
              <div class="ashore-toggle" id="proof_toggle_changes">
                <label class="switch">
                  <input
                      type="checkbox"
                      v-model="userSettings.approveWithChanges"
                  />
                  <div class="slider round"></div>
                </label>
                Approve With Changes
                <label>
                  <img
                      id="proof_toggle_approvewithchanges_icon"
                      src="/img/icon-info.svg"
                      class="ml-2"
                      style="height: 20px"
                  />
                  <b-tooltip
                      target="proof_toggle_approvewithchanges_icon"
                      triggers="hover"
                  >
                    Turn this toggle on to allow your approvers to say, "make
                    these changes but I don't need to see a new version". We'll
                    treat the proof as approved.
                  </b-tooltip>
                </label>
              </div>
              <!--/Toggle -->
              <!--Toggle -->
              <div class="ashore-toggle">
                <label class="switch">
                  <input
                      type="checkbox"
                      v-model="userSettings.limitVersionVisibility"
                  />
                  <div class="slider round"></div>
                </label>
                Limit Version Visibility
                <label>
                  <img
                      id="proof_toggle_limitVersionVisibility_icon"
                      src="/img/icon-info.svg"
                      class="ml-2"
                      style="height: 20px"
                  />
                  <b-tooltip
                      target="proof_toggle_limitVersionVisibility_icon"
                      triggers="hover"
                  >
                    Turn this toggle on to prevent approvers from viewing proof
                    versions sent to approvers in other workflow stages.
                  </b-tooltip>
                </label>
              </div>
              <!--/Toggle -->
              <!--Toggle -->
              <div
                  class="ashore-toggle"
                  id="proof_toggle_blockCommentingAfterProofDecision"
              >
                <label class="switch">
                  <input
                      data-cy="blockCommentingCheckbox"
                      type="checkbox"
                      v-model="userSettings.blockCommentingAfterProofDecision"
                  />
                  <div class="slider round"></div>
                </label>
                Block Commenting After Proof Decision
                <label>
                  <img
                      id="proof_toggle_blockCommentingAfterProofDecision_icon"
                      src="/img/icon-info.svg"
                      class="ml-2"
                      style="height: 20px"
                  />
                  <b-tooltip
                      target="proof_toggle_blockCommentingAfterProofDecision_icon"
                      triggers="hover"
                  >
                    Turn this toggle on to prevent approvers from submitting
                    comments after making a decision.
                  </b-tooltip>
                </label>
              </div>
              <!--/Toggle -->
            </div>
          </div>
          <!-- Default Email Settings -->
          <div class="col-md-6">
            <div class="sub-header-text" style="margin-bottom: 10px; margin-top: 10px">Default Email Settings</div>
            <div class="col-md-12">
              <div class="ashore-toggle">
                <label class="switch">
                  <input
                      type="checkbox"
                      v-model="userSettings.notifyFilesSent"
                  />
                  <div class="slider round"></div>
                </label>
                Notify me if files have been successfully sent
              </div>
              <div class="ashore-toggle">
                <label class="switch">
                  <input
                      type="checkbox"
                      v-model="userSettings.notifyFilesApproved"
                  />
                  <div class="slider round"></div>
                </label>
                Notify me if files have been approved
              </div>
              <div class="ashore-toggle">
                <label class="switch">
                  <input
                      type="checkbox"
                      v-model="userSettings.notifyNewComments"
                  />
                  <div class="slider round"></div>
                </label>
                Notify me if my contacts comment on a file
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-25">
          <reminderFrequency ref="reminderFreq"/>
      </div>
      <div class="row mx-0">
        <div class="col-md-12 d-flex justify-content-end notification-footer">
          <a
              class="green-btn mobile-btn-display"
              data-cy="updatedProfileDefault"
              style="margin-top: 6px"
              @click="update"
          >Update</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.field-item h3 {
  font-size: 18px;
}

.field-item {
  padding: 0px;
}

.green-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  background: #019255;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: white;
  cursor: pointer;
}

.notification-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #252526;
}

.green-btn:hover {
  opacity: 0.9;
}

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #252526;
}

.sub-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #707073;
}

@media screen and (max-width: 766px) {

  .mobile-btn-display {
    width: 100%;
  }
}

/deep/ .form-group {
  margin-bottom: 10px !important;
}

.notification-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 12px 24px;
  background: #F4F4F6;
  height: 62px;
}

</style>
