<template>
  <div>
    {{ formValid }}
    <div class="login-page registration-page">
      <div class="container-fluid">
        <div class="row">
          <!-- Sidebar -->
          <div class="col-lg-4 col-md-5 m-auto">
            <div class="generic-sidebar">
              <div v-if="accountLogo" class="ashore-logo-registration">
                <img :src="accountLogo" class="account-logo"/>
              </div>
              <div class="set-password-text my-4">Set Your Password</div>
              <div :class="['form-group', inputValid(passwordValid)]">
                <label class="label-text">Your Password</label>
                <input
                    type="password"
                    class="form-control reset-input"
                    placeholder="Password"
                    v-model="password"
                />
              </div>
              <div :class="['form-group', inputValid(passwordMatch)]">
                <label class="label-text">Password Confirmation</label>
                <input
                    type="password"
                    class="form-control reset-input"
                    placeholder="Password Confirmation"
                    v-model="password_confirm"
                    v-on:keyup.enter="SetPassword()"
                />
              </div>
              <a
                  style="margin-top: 24px"
                  v-on:click="SetPassword()"
                  class="button green-btn"
              >
                <b-spinner
                    variant="success"
                    v-show="working"
                    type="grow"
                    small
                ></b-spinner>
                Set Password</a
              >
              <b-alert
                  :show="dismissCountDown"
                  dismissible
                  :variant="variant"
                  @dismissed="dismissCountDown = 0"
                  @dismiss-count-down="countDownChanged"
                  style="margin-top: 25px"
              >
                <p>{{ errorMessage }}</p>
                <b-progress
                    :variant="variant"
                    :max="dismissSecs"
                    :value="dismissCountDown"
                    height="4px"
                ></b-progress>
              </b-alert>
              <!--              <div class="sidebar-button-group">-->
              <!--                <a href="/" class="button button-dark button-gray button-block"-->
              <!--                  >Login Instead</a-->
              <!--                >-->
              <!--                <a-->
              <!--                  href="/sign-up"-->
              <!--                  class="button button-dark button-gray button-block"-->
              <!--                  >Create an Account</a-->
              <!--                >-->
              <!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.account-logo {
  width: 50px;
  height: 50px;
}

.set-password-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.label-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

.reset-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.green-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #F9F9F9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.login-page {
  background-color: #019255;
}
</style>

<script>
import UserApi from "../generated/api/UserApi";
import UpdatePasswordRequest from "../generated/model/UpdatePasswordRequest";
import { getAccountLogo } from "../utils/helpers";

export default {
  components: {},
  data() {
    return {
      password: "",
      password_confirm: "",
      passwordValid: false,
      passwordMatch: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      errorMessage: "",
      variant: "warning",
      working: false,
    };
  },
  computed: {
    formValid: function () {
      this.passwordValid =
          this.password.length === 0 ? true : this.password.length > 3;
      this.passwordMatch = this.password == this.password_confirm;
      return "";
    },
    accountLogo: function () {
      return getAccountLogo();
    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    inputValid: function (val) {
      return val ? "has-success" : "has-error";
    },
    allFormElementsValid: function () {
      return this.passwordValid && this.passwordMatch;
    },
    async SetPassword() {
      if (this.$route.query.h === undefined) {
        this.errorMessage = "password reset token is invalid";
        this.showDismissibleAlert = true;
        this.variant = "warning";
        this.showAlert();
      } else if (!this.allFormElementsValid() || this.password.length === 0) {
        this.errorMessage = "Password is not valid";
        this.showDismissibleAlert = true;
        this.variant = "warning";
        this.showAlert();
      } else {
        this.errorMessage = "Password is updating";
        this.showDismissibleAlert = true;
        this.variant = "success";
        this.showAlert();
        this.working = true;

        let validationHash = this.$route.query.h;
        let self = this;
        this.working = true;
        await this.$A.UserService.UpdatePasswordViaToken(
            validationHash,
            this.password,
            this.password_confirm
        );
      }
    },
  },
};
</script>
