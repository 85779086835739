<script>
import modalUploadImage from "../modals/modal-upload-image";
import smtpNoReplyEmail from "./setting-smtp-no-reply-email";

export default {
  name: "accountInformation",
  data() {
    return {
      user: _.get(this, '$A.Auth', {}),
      oldNoReplyEmail: _.get(this, '$A.Auth.account.noReplyEmail', ""),
      newNoReplyEmail: _.get(this, '$A.Auth.account.noReplyEmail', ""),
      oldWhiteLabelDomainName: _.get(this, '$A.Auth.account.whiteLabelDomainName', ""),
      newWhiteLabelDomainName: _.get(this, '$A.Auth.account.whiteLabelDomainName', ""),
      validated: false,
    };
  },
  components: {
    modalUploadImage,
    smtpNoReplyEmail
  },
  computed: {
    curAccount: function() {
      return _.get(this, 'user.account', {});
    },
    curLogo: function () {
      return this.$A.CurLogo();
    },
    isDisabled: function () {
      return !_.get(this, 'curAccount.subscriptionState.whiteLabelEnabled', false);
    },
    isPersonalStarterOrStandardSku: function () {
      const curSku = _.get(this, 'curAccount.subscriptionState.sku');
      const allowedSku = ['personal-4', 'freelancer-3', 'standard-1'];
      return allowedSku.includes(curSku);
    }
  },
  methods: {
    deleteAccount: function () {
      this.$refs.deleteAccountModal.openModal();
    },
    showUploadImageModal: function () {
      this.$refs.uploadImageModal.openModal();
    },
    updateAccountInfo: async function () {
      let account = await this.$A.AccountService.UpdateAccount(
          this.curAccount.name,
          null,
          this.curAccount.timeZone,
          this.curAccount.dateFormat,
          this.newWhiteLabelDomainName,
          null
      );

      if (account) {
        this.$A.Auth.account = account;
        this.$A.SaveSessionData(this.$A.Auth);
      }

      if (this.newWhiteLabelDomainName !== this.oldWhiteLabelDomainName) {
        this.oldWhiteLabelDomainName = this.newWhiteLabelDomainName;
        this.$A.AlertUser(
            "Please Allow 24 hours before using this new domain. The SSL cert will be provisioned as soon as possible.",
            "success",
            30000
        );
      }
    },
    get(object, path, defaultValue) {
      return _.get(object, path, defaultValue);
    },
  },
  mounted() {
    let self = this;
    this.$root.$on("account::logo::updated", function (image) {
      _.set(self, 'user.account.whiteLabelLogoUri', image);

      self.$emit("uploadAccountLogo", image);
    });
    this.$root.$on("account::delete::confirmation", function () {
      self.$A.AccountService.DeleteAccount();
    });
  },
};
</script>

<template>
  <div style="background-color: #eef2f5!important"
       class="on-mobile text-dark d-flex justify-content-center justify-content-md-center flex-md-nowrap pb-5">
    <div class="col-md-5 col-lg-5 pt-3 ml-lg-4">
      <!-- Account Logo -->
      <b-card class="mr-md-3 account-logo-card">
        <b-card-text class="pt-4 px-4 text-dark card-header-text">Account Logo</b-card-text>
        <b-card-body class="d-flex w-100 px-4 pb-4 align-items-center">
          <div class="account-image" :style="{ backgroundImage: 'url(' + curLogo + ')' }"></div>
        </b-card-body>
        <div class="border-top-0 col-12 org-footer">
          <b-button v-show="!get($A, 'Auth.account.subscriptionState.whiteLabelEnabled', false)"
                    class="button button-block-organization"
                    @click="$A.BuyNow()"
                    v-b-tooltip.hover="'Upgrade to rebrand Ashore'">
            Feature Locked <img src="/img/feature-lock.svg" class="feature-icon"/>
          </b-button>
          <a
              v-show="get($A, 'Auth.account.subscriptionState.whiteLabelEnabled', false)"
              class="button button-light button-green button-green-organization"
              @click="showUploadImageModal">Upload Logo</a
          >
        </div>
      </b-card>
      <!-- Delete Account -->
      <b-card class="mr-md-3 mt-3">
        <b-card-text class="px-4 pt-4 text-dark card-header-text">Delete Account</b-card-text>
        <b-card-body class="pb-4 px-4">
          <div class="description-text">
            This action will delete all files and user data on your account.
            It cannot be undone.
          </div>
        </b-card-body>
        <div class="border-top-0 col-12 org-footer">
          <a class="button button-light delete-button" @click="deleteAccount">
            Delete Account
          </a>
        </div>
      </b-card>
    </div>
    <div class="col-md-6 col-lg-5 mt-3 px-1">
      <!-- Organization Settings -->
      <b-card class="p-0">
        <b-card-text class="px-4 pt-4 mb-0 text-dark card-header-text">Organization Settings</b-card-text>
        <b-card-body class="px-4 py-4">
          <!-- Company Name -->
          <div class="form-group mb-0">
            <label class="input-text">Company Name</label>
            <input data-cy="companyNameInput"
                   type="text"
                   class="form-control organization-settings"
                   placeholder="Company Name"
                   v-model="curAccount.name"
            />
          </div>
          <!-- Domain -->
          <div class="form-group mb-0"
               @click="!get(curAccount, 'subscriptionState.domainWhiteLabelEnabled', false) ? $A.BuyNow(): null">
            <label class="font-weight-normal mb-0 mt-4 input-text">Domain</label>
            <p class="mb-1 mt-1 text-justify description-text">
              Whitelabel your proof review links.
              Point the CNAME Record of your subdomain to “http://ingress.ashoreapp.com ” 
              and update the field below with your new domain. 
              Within 24 hours, an SSL certificate will be installed for your new domain.
            </p>
            <input
                type="text"
                :class="'form-control organization-settings ' + ((isDisabled || isPersonalStarterOrStandardSku) ? 'disabled-input': '')"
                v-model="newWhiteLabelDomainName"
                :disabled="isDisabled || isPersonalStarterOrStandardSku"
            />
          </div>
          <!-- Date Format -->
          <div class="form-group mb-4">
            <label class="font-weight-normal mb-0 mt-4 input-text">Date Format</label>
            <select class="form-control form-select organization-settings mt-2" v-if="get($A, 'Auth.account', {})" v-model="$A.Auth.account.dateFormat">
              <option value="MM-DD-YYYY">MM-DD-YYYY</option>
              <option value="DD-MM-YYYY">DD-MM-YYYY</option>
              <option value="YYYY-MM-DD">YYYY-MM-DD</option>
            </select>
          </div>
        </b-card-body>
        <!-- Save -->
        <div class="org-footer col-12">
          <a
              data-cy="updateAccountInfoBtn"
              class="button-light button-green org-btn button-block-organization float-right mb-3 pt-5 text-center mobile-btn"
              @click="updateAccountInfo"> Save
          </a>
        </div>
      </b-card>
      <!-- Email Settings Account -->
      <smtp-no-reply-email :is-account-settings="true" :noReplyEmail="newNoReplyEmail"/>
    </div>
    <modalUploadImage type="logo" ref="uploadImageModal"/>
    <modal
        ref="deleteAccountModal"
        event-to-emit-on-confirmation="account::delete::confirmation"
        name="deleteAccountModal"
        question="Are you sure you would like to delete your entire account?"
        body="This will delete all sub users and all proofs"
        confirmButtonText="Delete Forever"
        buttonCss="button-red"
    />
  </div>
</template>

<style scoped>

.field-item h3 {
  font-size: 18px;
}

.account-image {
  width: 191px;
  height: 191px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
  display: block;
}

.organization-tab {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.input-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
  opacity: 1;
}

.button-block-organization {
  padding: 9px 17px !important;
  font-weight: normal;
  height: 38px;
  background: #F44271;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button-green-organization {
  padding: 9px 17px !important;
  font-weight: normal;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.delete-button {
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  padding: 9px 17px !important;
  font-weight: normal;
  height: 38px;
  background: #EB4654;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.feature-icon {
  margin-bottom: 2px;
  margin-left: 4px;
  height: 22px;
}

.org-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 12px 24px;
  background: #F4F4F6;
  height: 62px;
}

.org-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  position: static;
  width: 108px;
  height: 38px;
  left: 457px;
  top: 12px;
  background: #019256;
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

::v-deep .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0rem;
}

.card-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #3E3E40;
}

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;

}

/deep/ label.header-text {
  opacity: 1;
}

/deep/ .account-logo-card .card-body {
  min-height: 270px !important;
  padding: 0rem;
}

.description-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #909092;
  margin: 4px 0px;
}

.organization-settings {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  display: block;
  width: 100%;
  height: 37px;
  padding: 2px 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #444444;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid lightgray;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  border-radius: 5px;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.disabled-input {
  background-color: #e9ecef !important;
  opacity: 1 !important;
}

.upload-container {
  border: 2px dashed #B5B5B8;
  box-sizing: border-box;
  border-radius: 6px;
}

.upload-img {
  width: 30px;
  height: 30px;
}

.upload-file-text {
  ont-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #006F41;
}

.upload-logo-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

.upload-logo-formats {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #707073;
}

@media (max-width: 576px) {

  .on-mobile {
    flex-wrap: wrap;
  }

  .button-block-organization {
    width: 160px;
  }

  .feature-icon {
    margin-top: 4px;
  }

}

@media screen and (max-width: 991px) {

  .button-block-organization {
    width: 100% !important;
  }

  .button-green-organization {
    width: 100%;
  }

  .button-green {
    width: 100%;
  }

  .button-block {
    width: 100%;
  }

  .delete-button {
    width: 100%;
  }

}

</style>
